<template>
    <div class="home" v-if="resData!=null">
        <location :locationArr="locationArr"/>
        <div class="particularsBox">
            <h3>{{resData.fcName}}</h3>
            <p>发布时间:{{resData.fcDate}}</p>
            <div class="wire"></div>
            <div class="particularsCon" v-html="resData.fcContent"></div>
        </div>
    </div>
</template>

<script>
    import location from '@/components/location.vue'

    export default {
        name: 'memberInfoDetail',
        components: {
            location
        },
        data() {
            return {
                resData: null,
                page: 0,
                locationArr: null
            }
        },
        mounted() {
            this.locationArr = [{"title": "会员资讯", "url": "/memberInfo"}];
            this.getInfoData()
        },
		created() {
			if (/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						// console.log(123)
			        this.$router.push({
			            path: "/mobile"
			        });
			}
		},
        methods: {
            getInfoData() {
                if (this.$route.path != '/memberInfo/detail') {
                    return;
                }
                const id = this.$route.query.id
                if (typeof (id) == 'undefined' || id == '') {
                    return;
                }
                this.$http.hyzqHyzxDetail({id: id}).then(res => {
                    if (res.code == 200) {
                        this.resData = res.data
                    }
                })
            }
        },
        watch: {
            $route: 'getInfoData'
        }
    }
</script>
<style type="text/css">
    @import '~@/assets/css/Par.css';
</style>
